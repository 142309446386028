<template>
  <div>

    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <h4>Contractor Applications</h4>
              <h5>0</h5>

            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <h4>Executed Projects</h4>
              <h5>0</h5>

            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h4 class="py-2">Notifications</h4>

          <div class="card">
            <div class="card-body">
             

                <h4 class="text-warning">New Application </h4>
                <hr>
              
              <h6>Your have a new contractor application submission by Deicon Nig. Ltd.</h6>
            </div>
          </div>


        </div>
      </div>
    </div>



  </div>
</template>

<script>


export default {
  components: {

  },
}
</script>

<style></style>
